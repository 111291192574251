button:disabled {
  background-color: #f5f5f5;
}

.dots_custom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dots_custom ul {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
  border: 1px solid transparent;
  border-radius: 100%;
  padding: 0.2em;
}

.dots_custom li:not(:last-child) button {
  align-items: center;
  border: none;
  background: #222;
  color: transparent;
  cursor: pointer;
  display: flex;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active {
  border: 1px solid #222;
  border-radius: 100%;
  padding: 0.2em;
}

.dots_custom li.slick-active button {
  background-color: #222;
}

.container-box {
  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  position: relative;
}
.small-container {
  /* max-width: 300px; */
  margin: 0 auto;
}
.container-box .arrow {
  position: absolute;
  z-index: 20;
  top: 30%;
}
.container-box .arrow.arrow-left {
  left: -60px;
}
.container-box .arrow.arrow-right {
  right: -60px;
}

@media (max-width: 800px) {
  .container-box .arrow {
    display: none;
  }
}
