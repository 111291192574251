.logo {
  content: url('/image/meetmall-logo.png');
  position: relative;
  block-size: 90px;
}
.faceBookLogo {
  content: url('/image/free-icon-font-facebook-6422199.svg');
  position: relative;
}
.hamburgerIcon {
  content: url('/image/burger-menu-svgrepo-com.svg');
  position: relative;
}

.gray-bg {
  background-color: RGBA(0, 0, 0, 0.06);
}

.swal2-title {
  font-size: 20px;
}

.swal2-confirm {
  background-color: #3085d6;
  color: white;
}

.swal2-cancel {
  background-color: #aaa;
  color: white;
}

.swal2-actions {
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
}
.swal2-html-container {
  text-align: left !important;
}

.custom-container {
  z-index: 9999;
}
