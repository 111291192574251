.order-detail {
  .chakra-table {
    border-top: 1px solid black;
  }
  tr {
    border-bottom: 1px solid #dfe3e9;
    td.data {
      width: 70%;
    }
    td.title {
      width: 20%;
      background-color: #f4f4f4;
    }
    td.prod-title {
      width: 20%;
    }
    td.prod-data {
      width: 80%;
    }
  }
  .mobile-tr {
    border-bottom: 1px solid #dfe3e9;
    td.data {
      width: 60%;
    }
    td.title {
      padding-left: 10px;
      text-align: left;
      width: 40%;
      background-color: #f4f4f4;
    }
    td.prod-title {
      width: 20%;
    }
    td.prod-data {
      width: 80%;
    }
  }
}
